import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8 pb-5" }
const _hoisted_2 = { class: "col-xxl-9" }
const _hoisted_3 = { class: "card-header border-0 pt-5" }
const _hoisted_4 = { class: "card-title align-items-start flex-column" }
const _hoisted_5 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_6 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { class: "symbol symbol-50px me-5" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "text-gray-800 mb-1" }
const _hoisted_15 = { class: "text-gray-800 mb-1" }
const _hoisted_16 = { class: "text-gray-800 mb-1" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  class: "modal fade show",
  tabindex: "-1",
  id: "kt_modal_add",
  "aria-modal": "true",
  role: "dialog",
  ref: "newTargetModalRef"
}
const _hoisted_19 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_20 = { class: "modal-content" }
const _hoisted_21 = { class: "modal-header" }
const _hoisted_22 = { class: "fw-bolder" }
const _hoisted_23 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_modal_add_event_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_24 = { class: "svg-icon svg-icon-1" }
const _hoisted_25 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_26 = { class: "row mb-7" }
const _hoisted_27 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_28 = { class: "col-lg-8" }
const _hoisted_29 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_30 = { class: "row mb-7" }
const _hoisted_31 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_32 = { class: "col-lg-8" }
const _hoisted_33 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_34 = { class: "row mb-7" }
const _hoisted_35 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_36 = { class: "col-lg-8" }
const _hoisted_37 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_38 = { class: "row mb-7" }
const _hoisted_39 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_40 = { class: "col-lg-8" }
const _hoisted_41 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_42 = { class: "row mb-7" }
const _hoisted_43 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_44 = { class: "col-lg-8" }
const _hoisted_45 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_46 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" }
const _hoisted_47 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_48 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_49 = { class: "fw-bold" }
const _hoisted_50 = { class: "fs-6 text-gray-600" }
const _hoisted_51 = { class: "modal-footer" }
const _hoisted_52 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}
const _hoisted_53 = { class: "col-xxl-3" }
const _hoisted_54 = { class: "card" }
const _hoisted_55 = { class: "card-title align-items-start flex-column mt-8 mx-8" }
const _hoisted_56 = { class: "fw-bolder mb-0 text-dark" }
const _hoisted_57 = { class: "card-body" }
const _hoisted_58 = { class: "border border-gray-300 border-dashed rounded py-2 mb-3" }
const _hoisted_59 = { class: "d-flex align-items-center m-3" }
const _hoisted_60 = { class: "svg-icon svg-icon-1 me-2" }
const _hoisted_61 = {
  class: "fs-3 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "4500",
  "data-kt-countup-prefix": "$"
}
const _hoisted_62 = { class: "fw-bold fs-6 text-gray-400 me-5" }
const _hoisted_63 = { class: "border border-gray-300 border-dashed rounded py-2 mb-3" }
const _hoisted_64 = { class: "d-flex align-items-center m-3" }
const _hoisted_65 = { class: "svg-icon svg-icon-1 svg-icon-primary me-2" }
const _hoisted_66 = {
  class: "fs-3 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "4500",
  "data-kt-countup-prefix": "$"
}
const _hoisted_67 = { class: "fw-bold fs-6 text-gray-400 me-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.widgetClasses, "card"])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("student.studentList")), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("student.numberStudent", { nb: _ctx.tableData.length })), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                class: "form-control form-control-solid w-200px ps-15",
                placeholder: _ctx.t('student.findStudent')
              }, null, 40, _hoisted_10), [
                [_vModelText, _ctx.search]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_KTDatatable, {
            "table-data": _ctx.studentsData,
            "table-header": _ctx.tableHeader,
            rowsPerPage: 40,
            loading: _ctx.loading
          }, {
            "cell-photo": _withCtx(({ row: student }) => [
              _createElementVNode("div", _hoisted_12, [
                (student.photo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.apiUrl + student.photo
                    }, null, 8, _hoisted_13))
                  : (_openBlock(), _createBlock(_component_el_avatar, {
                      key: 1,
                      shape: "square",
                      size: 50
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(student.firstName[0].toUpperCase()) + _toDisplayString(student.lastName[0].toUpperCase()), 1)
                      ]),
                      _: 2
                    }, 1024))
              ])
            ]),
            "cell-lastName": _withCtx(({ row: student }) => [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  class: "text-gray-800 text-hover-primary fs-6",
                  tag: "a",
                  to: `/student/${student._id}/overview`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(student.firstName + " " + student.lastName), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ]),
            "cell-gender": _withCtx(({ row: student }) => [
              _createElementVNode("div", null, [
                _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.t("student." + student.gender)), 1)
              ])
            ]),
            "cell-BirthDate": _withCtx(({ row: student }) => [
              _createElementVNode("div", null, [
                _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.moment(student.BirthDate).format("DD/MM/YYYY")), 1)
              ])
            ]),
            "cell-adress": _withCtx(({ row: student }) => [
              _createElementVNode("div", null, [
                _createElementVNode("a", _hoisted_16, _toDisplayString(student.adress), 1)
              ])
            ]),
            "cell-action": _withCtx(({ row: student }) => [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2",
                "data-bs-toggle": "modal",
                "data-bs-target": "#kt_modal_add",
                onClick: ($event: any) => (_ctx.selectStudent(student))
              }, _toDisplayString(_ctx.t("student.details")), 9, _hoisted_17)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_el_form, {
                classs: "form fv-plugins-bootstrap5 fv-plugins-framework",
                "label-position": "top",
                "label-width": "200px"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("h2", _hoisted_22, _toDisplayString(_ctx.t("student.parentSheet")), 1),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("span", _hoisted_24, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.t("student.address")) + " :", 1),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.selectedData.adress), 1)
                      ])
                    ]),
                    _createVNode(_component_el_divider, { "content-position": "left" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("student.fatherSection")), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.t("student.fullname")) + " :", 1),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.selectedData.father.lastName +
                        " " +
                        _ctx.selectedData.father.firstName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.t("student.phone")) + " :", 1),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.selectedData.father.phone), 1)
                      ])
                    ]),
                    _createVNode(_component_el_divider, { "content-position": "left" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("student.motherSection")), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.t("student.fullname")) + " :", 1),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.selectedData.mother.lastName +
                        " " +
                        _ctx.selectedData.mother.firstName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.t("student.phone")) + " :", 1),
                      _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.selectedData.mother.phone), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("span", _hoisted_47, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
                      ]),
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, [
                          _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.t("student.parentMoreInfo")), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("button", _hoisted_52, _toDisplayString(_ctx.t("student.close")), 1)
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ], 512)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_53, [
      _createElementVNode("div", _hoisted_54, [
        _createElementVNode("h3", _hoisted_55, [
          _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.t("stats.studentsStats")), 1)
        ]),
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("div", _hoisted_58, [
            _createElementVNode("div", _hoisted_59, [
              _createElementVNode("span", _hoisted_60, [
                _createVNode(_component_inline_svg, { src: "media/svg/avatars/005-girl-2.svg" })
              ]),
              _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.girls), 1)
            ]),
            _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.t("stats.numberOfGirls")), 1)
          ]),
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("div", _hoisted_64, [
              _createElementVNode("span", _hoisted_65, [
                _createVNode(_component_inline_svg, { src: "media/svg/avatars/009-boy-4.svg" })
              ]),
              _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.boys), 1)
            ]),
            _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.t("stats.numberOfBoys")), 1)
          ])
        ])
      ])
    ])
  ]))
}