
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface IStudents {
  _id: string;
  firstName: string;
  lastName: string;
  BirthDate: string;
  gender: string;
  classRoom: string;
  classRoomName: string;
  father: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  mother: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  photo: string;
  adress: string;
}
export default defineComponent({
  name: "Student-list",
  components: {
    KTDatatable,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const tableHeader = ref([
      {
        name: t("student.photo"),
        key: "photo",
      },
      { name: t("student.fullname"), key: "lastName", sortable: true },

      {
        name: t("student.gender"),
        key: "gender",
        sortable: true,
      },
      {
        name: t("student.birthdate"),
        key: "BirthDate",
        sortable: true,
      },
      { name: t("student.address"), key: "adress", sortable: true },
      { name: t("student.parentSheet"), key: "action" },
    ]);

    const studentsData = ref<Array<IStudents>>([]);
    const tableData = ref<Array<IStudents>>([]);
    const boys = ref(0);
    const girls = ref(0);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    // eslint-disable-next-line
    const selectedData = ref<any>({
      father: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      mother: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      adress: "",
    });
    const selectStudent = function (data) {
      let selected = {
        father: data.father,
        mother: data.mother,
        adress: data.adress,
      };
      selectedData.value = selected;
    };
    const loading = ref(true);
    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Eleves", []);
      let sy = await window.localStorage.getItem("activeSchoolarYear");
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = props.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          data.forEach((s) => {
            if (s.gender == "male") boys.value++;
            else girls.value++;
          });
          studentsData.value = data;
          tableData.value = data;
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));
    });
    const search = ref<string>("");
    const searchItems = () => {
      if (search.value !== "") {
        let results: Array<IStudents> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        studentsData.value = Object.assign([], results);
      } else studentsData.value = Object.assign([], tableData.value);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    return {
      t,
      moment,
      selectStudent,
      selectedData,
      studentsData,
      tableData,
      tableHeader,
      search,
      searchItems,
      apiUrl,
      boys,
      girls,
      loading,
    };
  },
});
